$fd-green: #43b02a;
$fd-dark-blue: #004165;
$fd-white: #fff;
$fd-dark-gray: #616365;
$fd-light-gray: #f4f4f4;
$fd-gray: #f2f2f2;
$fd-indigo-blue: #0072ce;
$fd-light-blue: #00a9e0;
$fd-disabled-btn-background: #e4e2e2;
$fd-yellow: #ffe200;
$fd-alert-yellow: #ffc107;
$substep-container-width: 200px;
$fd-orange: #FF6600;

$fs-color: #f2571a;

//layout background color
$fd-background-container: #f2f2f2;

// nav color
$fd-nav-icon: #93bee4;
$fd-nav-icon-focus:#297fca;

//header color
$fd-header-white: #fff;
$fd-header-blue: #004165;

$fd-button-green: #73C354;
